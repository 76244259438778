import React from 'react';
import { Button } from 'react-bootstrap';
import './css/Main.css'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import AboutUs from './About';
import Gallery_Section from './Gallery_section';
import Footer from './Footer';
import { Home_contact_us_button_click } from '../Links'
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { referNow } from './whatsapp';

const Carousel_section = () => {
    const navigate = useNavigate()

    return (
        <>
            <div className='carousel_Container'>
                <Image src={require('../assets/First image.png')}
                    className='Desktop_Home'
                />

                <div>
                    <Image src={require('../assets/mobile_first_image.jpg')}
                        className='Mobile_Home d-none'
                    />
                </div>

                <div className='container2'>
                    <div>
                        <img src={require('../assets/logo.png')} alt="Logo" width="50" height="50" />
                    </div>

                    <p>Discover why we are the perfect destination for your little one's entertainment and development. </p>
                    {/* <Button className='carousel_btn' onClick={() => { window.open(Home_contact_us_button_click, '_blank') }}>Contact Us</Button> */}

                    <Button className='d-none mobileContactBtn' style={{
                        background: '#FFA3BE 0% 0% no-repeat padding-box', marginLeft: 0
                    }} onClick={() => { referNow() }}>Contact Us</Button>

                    <Button className='desktopContactBtn' style={{
                        background: '#FFA3BE 0% 0% no-repeat padding-box'
                    }} onClick={() => { window.open(Home_contact_us_button_click, '_blank') }}>Contact Us</Button>


                </div>
            </div>

            <div className='service_container'>
                <h2>
                    Services Offered
                </h2>


                {/* Desktop View */}
                <div className='desktop-explore explore mt-2 d-flex'>
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper mb-4"
                    >
                        <SwiperSlide>  <div className='explore_zigzag'>
                            <div className='desktop-explore explore mt-2 d-flex'>
                                <div className='expore-text'><h4 style={{ 'line-height': '1.5' }}>Montessori Corner</h4>
                                <p>Discover a haven of holistic education and nurturing care for your little ones at our Montessori.</p>
                                    <Button style={{ width: '60%' }} onClick={() => navigate('/Montessori')}>Explore More</Button>
                                </div>
                                <div className='exploreImg'>
                                    <img src={require('../assets/Montessori.jpg')} />
                                </div>
                            </div>
                        </div></SwiperSlide>
                        <SwiperSlide>  <div className='explore_zigzag'>
                            <div className='desktop-explore explore mt-2 d-flex'>
                                <div className='expore-text'><h4 style={{ 'line-height': '1.5' }}>Baby feeding corner</h4>
                                <p>We understand the importance of nutrition in your child's growth. Our feeding room is a tranquil oasis where babies and toddlers can enjoy healthy meals.</p>
                                    <Button style={{ width: '60%' }} onClick={() => navigate('/FeedingRoom')}>Explore More</Button>
                                </div>
                                <div className='exploreImg'>
                                    <img src={require('../assets/FeedingRoom/feeding.png')} />
                                </div>
                            </div>
                        </div></SwiperSlide>
                        <SwiperSlide>  <div className='explore_zigzag'>
                            <div className='desktop-explore explore mt-2 d-flex'>
                                <div className='expore-text'><h4 style={{ 'line-height': '1.5' }}>Cafe</h4>
                                <p>Where every bite is a burst of fun and every sip is a taste of joy.</p>
                                    <Button style={{ width: '60%' }} onClick={() => navigate('/Cafe')}>Explore More</Button>
                                </div>
                                {/* <div className='Blue-arrow'>
                                    <img src={require('../assets/Blue_arrow.png')} />
                                </div> */}
                                <div className='exploreImg'>
                                    <img src={require('../assets/cafe.jpeg')} />
                                </div>
                            </div>
                        </div></SwiperSlide>
                        <SwiperSlide>  <div className='explore_zigzag'>
                            <div className='desktop-explore explore mt-2 d-flex'>
                                <div className='expore-text'><h4 style={{ 'line-height': '1.5' }}>Trampoline Mania</h4>
                                    <p>Bounce into excitement at our Trampoline area! This will definitely be an unforgettable experience! </p>
                                    <Button style={{ width: '60%' }} onClick={() => navigate('/Trampoline')}>Explore More</Button>
                                </div>
                                {/* <div className='Blue-arrow'>
                                    <img src={require('../assets/Blue_arrow.png')} />
                                </div> */}
                                <div className='exploreImg'>
                                    <img src={require('../assets/trampoline.jpeg')} />
                                </div>
                            </div>
                        </div></SwiperSlide>

                        {/* fOR FUTURE ENABLE */}
                        <SwiperSlide>  <div className='explore_zigzag'>
                            <div className='desktop-explore explore mt-2 d-flex'>
                                <div className='expore-text'><h4 style={{ 'line-height': '1.5' }}>Ball Pool</h4>
                                    <p>Endless joy awaits in our colorful ball pool. Dive, play, and enjoy to your heart's content.</p>
                                    <Button style={{ width: '60%' }} onClick={() => navigate('/Arcade')}>Explore More</Button>
                                </div>
                                {/* <div className='Blue-arrow'>
                                    <img src={require('../assets/Blue_arrow.png')} />
                                </div> */}
                                <div className='exploreImg'>
                                    <img src={require('../assets/ball_pool.jpeg')} />
                                </div>
                            </div>
                        </div></SwiperSlide>


                        <SwiperSlide>  <div className='explore_zigzag'>
                            <div className='desktop-explore explore mt-2 d-flex'>
                                <div className='expore-text'><h4 style={{ 'line-height': '1.5' }}>Eventhub</h4>
                                    <p> Your Premier Multipurpose Event Space! </p>
                                    <Button style={{ width: '60%' }} onClick={() => navigate('/EventHub')}>Explore More</Button>
                                </div>
                                {/* <div className='Blue-arrow'>
                                    <img src={require('../assets/Blue_arrow.png')} />
                                </div> */}
                                <div className='exploreImg'>
                                    <img src={require('../assets/eventhub.jpeg')} />
                                </div>
                            </div>
                        </div></SwiperSlide>
                        <SwiperSlide>  <div className='explore_zigzag'>
                            <div className='desktop-explore explore mt-2 d-flex'>
                                <div className='expore-text'><h4 style={{ 'line-height': '1.5' }}>Imagination Oasis</h4>
                                    <p>Welcome to our pretend play wonderland, where imagination knows no bounds and adventures come to life! </p>
                                    <Button style={{ width: '60%' }} onClick={() => navigate('/Oasis')}>Explore More</Button>
                                </div>
                                {/* <div className='Blue-arrow'>
                                    <img src={require('../assets/Blue_arrow.png')} />
                                </div> */}
                                <div className='exploreImg'>
                                    <img src={require('../assets/oasis.JPG')} />
                                </div>
                            </div>
                        </div></SwiperSlide>
                    </Swiper>
                </div>

                {/* // Mobile View */}
                <div className='mobile-explore explore mt-2 d-none'>
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper mb-4"
                    >
                        <SwiperSlide>  <div className='explore_zigzag'>
                            <div className='mobileBG'>
                                <div className='exploreImg'>
                                    <img src={require('../assets/Montessori.jpg')} />
                                </div>
                                <div className='expore-text'><h4 style={{ 'line-height': '1.5' }}>Montessori Corner</h4>
                                <p>Discover a haven of holistic education and nurturing care for your little ones at our Montessori.</p>
                                    <Button style={{ width: '60%' }} onClick={() => navigate('/Montessori')}>Explore More</Button>
                                </div>
                            </div>
                        </div></SwiperSlide>

                        <SwiperSlide>  <div className='explore_zigzag'>
                            <div className='mobileBG'>
                                <div className='exploreImg'>
                                    <img src={require('../assets/FeedingRoom/feeding.png')} />
                                </div>
                                <div className='expore-text'><h4 style={{ 'line-height': '1.5' }}>Baby feeding corner</h4>
                                <p>We understand the importance of nutrition in your child's growth. Our feeding room is a tranquil oasis where babies and toddlers can enjoy healthy meals.</p>
                                    <Button style={{ width: '60%' }} onClick={() => navigate('/FeedingRoom')}>Explore More</Button>
                                </div>
                            </div>
                        </div></SwiperSlide>

                        <SwiperSlide>
                            <div className='explore_zigzag'>
                                <div className='mobileBG'>
                                    <div className='exploreImg'>
                                        <img src={require('../assets/cafe.jpeg')} />
                                    </div>
                                    <div className='expore-text'><h4 style={{ 'line-height': '1.5' }}>Cafe</h4>
                                        <p>Where every bite is a burst of fun and every sip is a taste of joy.</p>
                                        <Button style={{ width: '60%' }} onClick={() => navigate('/Cafe')}>Explore More</Button>
                                    </div>
                                </div>
                            </div></SwiperSlide>
                        <SwiperSlide>  <div className='explore_zigzag'>
                            <div className='mobileBG'>
                                <div className='exploreImg'>
                                    <img src={require('../assets/trampoline.jpeg')} />
                                </div>
                                <div className='expore-text'><h4 style={{ 'line-height': '1.5' }}>Trampoline Mania</h4>
                                    <p>Bounce into excitement at our Trampoline area! This will definitely be an unforgettable experience!</p>
                                    <Button style={{ width: '60%' }} onClick={() => navigate('/Trampoline')}>Explore More</Button>
                                </div>
                            </div>
                        </div></SwiperSlide>

                        {/* fOR FUTURE ENABLE */}
                        <SwiperSlide>  <div className='explore_zigzag'>
                        <div className='mobileBG'>
                            <div className='exploreImg'>
                                <img src={require('../assets/ball_pool.jpeg')} />
                            </div>
                            <div className='expore-text'><h4 style={{ 'line-height': '1.5' }}>Ball Pool</h4>
                                <p>Endless joy awaits in our colorful ball pool. Dive, play, and enjoy to your heart's content.</p>
                                <Button style={{ width: '60%' }} onClick={() => navigate('/Arcade')}>Explore More</Button>
                            </div>
                            </div>
                        </div></SwiperSlide>


                        <SwiperSlide>  <div className='explore_zigzag'>
                            <div className='mobileBG'>
                                <div className='exploreImg'>
                                    <img src={require('../assets/eventhub.jpeg')} />
                                </div>
                                <div className='expore-text'><h4 style={{ 'line-height': '1.5' }}>Eventhub</h4>
                                    <p> Your Premier Multipurpose Event Space!</p>
                                    <Button style={{ width: '60%' }} onClick={() => navigate('/EventHub')}>Explore More</Button>
                                </div>
                            </div>
                        </div></SwiperSlide>
                        <SwiperSlide>  <div className='explore_zigzag'>
                            <div className='mobileBG'>
                                <div className='exploreImg'>
                                    <div className='img_top w-100'>
                                        <img src={require('../assets/oasis.JPG')} />
                                    </div>
                                </div>
                                <div className='expore-text'><h4 style={{ 'line-height': '1.5' }}>Imagination Oasis- Pretend play area</h4>
                                    <p>Welcome to our pretend play wonderland, where imagination knows no bounds and adventures come to life!"</p>
                                    <Button style={{ width: '60%' }} onClick={() => navigate('/Oasis')}>Explore More</Button>
                                </div>
                            </div>
                        </div></SwiperSlide>
                    </Swiper>

                </div>

                <div className='serviceImg'>

                <div className='img_container'>
                        <Card onClick={() => navigate('/Montessori')}>
                            <div className='img_top'>
                                <Card.Img variant="top" src={require('../assets/Montessori.jpg')} />
                            </div>
                            <Card.Body>
                                <Card.Title>Montessori Corner</Card.Title>
                                <Card.Text>
                                    Explore More
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                    </svg>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                        <div className='img_container'>
                        <Card onClick={() => navigate('/FeedingRoom')}>
                            <div className='img_top'>
                                <Card.Img variant="top" src={require('../assets/FeedingRoom/feedingWhite.jpeg')} />
                            </div>
                            <Card.Body>
                                <Card.Title>Baby feeding corner</Card.Title>
                                <Card.Text>
                                    Explore More
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                    </svg>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='img_container'>
                        <Card onClick={() => navigate('/Cafe')}>
                            <div className='img_top'>
                                <Card.Img variant="top" src={require('../assets/cafe.jpeg')} />
                            </div>
                            <Card.Body>
                                <Card.Title>Cafe</Card.Title>
                                <Card.Text>
                                    Explore More
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                    </svg>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='img_container'>
                        <Card onClick={() => navigate('/Trampoline')}>
                            <div className='img_top'>
                                <Card.Img variant="top" src={require('../assets/trampoline.jpeg')} />
                            </div>
                            <Card.Body>
                                <Card.Title>Trampoline Mania</Card.Title>
                                <Card.Text>
                                    Explore More
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                    </svg>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='img_container'>
                        <Card onClick={() => navigate('/Arcade')}>
                            <div className='img_top'>
                                <Card.Img variant="top" src={require('../assets/ball_pool.jpeg')} />
                            </div>
                            <Card.Body>
                                <Card.Title>Ball Pool</Card.Title>
                                <Card.Text>
                                    Explore More
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                    </svg>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    
                    <div className='img_container'>
                        <Card onClick={() => navigate('/EventHub')}>
                            <div className='img_top w-100'>
                                <Card.Img variant="top" src={require('../assets/eventhub.jpeg')} />
                            </div>
                            <Card.Body>
                                <Card.Title>Eventhub</Card.Title>
                                <Card.Text>
                                    Explore More
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                    </svg>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='img_container'>
                        <Card onClick={() => navigate('/Oasis')}>
                            <div className='img_top w-100'>
                                <Card.Img variant="top" src={require('../assets/oasis.JPG')} />
                            </div>
                            <Card.Body>
                                <Card.Title>Imagination Oasis</Card.Title>
                                <Card.Text>
                                    Explore More
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                    </svg>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                </div>
            </div >


            {/* comment for later use */}
            {/* <div className='section4 mt-3 mb-3'>
                <Container>
                    <Row>
                        <Col xs={6} md={4}>
                            <Card>
                                <div className='img_top w-100'>
                                    <Card.Img variant="top" src={require('../assets/section4_child1.png')} />
                                </div>
                                <Card.Body>
                                    <Card.Title>Eventhub</Card.Title>
                                    <Card.Text onClick={() => navigate('/EventHub')}>
                                        Explore More
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                        </svg>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={6} md={4}>
                            <Card>
                                <div className='img_top w-100'>
                                    <Card.Img variant="top" src={require('../assets/section4_child2.png')} />
                                </div>
                                <Card.Body>
                                    <Card.Title>Imagination Oasis</Card.Title>
                                    <Card.Text onClick={() => navigate('/Oasis')}>
                                        Explore More
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                        </svg>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={6} md={4}>
                            <div>
                                <img src={require('../assets/child.png')} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div> */}


            {/* comment for later use */}
            {/* <div className='party-hall-zigzag'>
                <div className='party-hall mt-2 d-flex'>
                    <div className='exploreImg mobileParty'>
                        <img src={require('../assets/partyHall.png')} />
                    </div>
                    <div className='red_arrow'>
                        <img src={require('../assets/pink_arrow.png')} />
                    </div>

                    <div className='partyHall-text'>
                        <h4 style={{ 'line-height': '1.5' }}>Party Hall</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        </p>
                        <h6>Lorem Ipsum</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                        <Button className='partyBtn'>Book Now</Button>
                    </div>

                    <div className='red_mobile_arrow d-none'>
                        <img src={require('../assets/pink_arrow.png')} />
                    </div>
                </div>
            </div> */}

            <div className='why-zenko-zigzag'>
                <div className='why_zenko mt-2 mb-4 d-flex'>
                    <div className='whyZenko_leftImg'>
                        <img src={require('../assets/zenko_left.png')} />
                    </div>

                    <div className='whyZenko-text'>
                        <h4 className='fw-bold mb-4'>Why Zenko?</h4>
                        <div className='why_child_img'>
                            <img src={require('../assets/kids_logo.png')} />
                        </div>
                        <div className='why_logo'>
                            <img src={require('../assets/logo.png')} />
                        </div>
                        <h6 className='m-0 mt-3'>Safety First</h6>
                        <p className='m-2'>Your child's safety is our top priority. Our play area is designed with childproofing in mind, featuring padded floors, soft play equipment, and vigilant staff ensuring a secure environment.
                        </p>
                        <h6 className='m-0 mt-3'>Educational Enrichment</h6>
                        <p className='m-2'>Playtime is learning time. Our play area incorporates educational elements, helping children develop vital skills such as problem-solving, social interaction, and fine motor skills.
                        </p>
                        <h6 className='m-0 mt-3'>Special Events and Parties</h6>
                        <p className='m-2'>Looking for a memorable venue for your child's birthday party or a special event? We offer party packages that include decorations, activities, and more.
                        </p>
                        <h6 className='m-0 mt-3'>Imagination Unleashed</h6>
                        <p className='m-2'>We believe in the power of play to stimulate creativity and cognitive development. Our play area is carefully curated to encourage imaginative play, offering themed play zones, puzzles, and interactive toys.
                        </p>
                    </div>

                    <div className='whyZenko_RightImg'>
                        <img src={require('../assets/zenko_right.png')} />
                    </div>
                </div>
            </div>

            <div className='plan-party-zigzag' style={{ 'marginTop': '5%' }}>
                <div className='plan-party d-flex' >

                    <div className='plan-party-Content pb-3'>
                        <h4 className='fw-bold mb-0 mt-4'>Contact us</h4>
                        <p>
                            Feel free to get in touch with us anytime! We're here to assist you, whether you have to plan an event, a workshop, have questions, special requests, or simply want to chat. Reach out to us and our friendly team will be delighted to help. Your satisfaction is our priority</p>
                        <Button className='d-none mobileContactBtn' style={{
                            background: '#FFA3BE 0% 0% no-repeat padding-box'
                        }} onClick={() => { referNow() }}>Contact Us</Button>

                        <Button className='desktopContactBtn contctbtn' style={{
                            background: '#FFA3BE 0% 0% no-repeat padding-box'
                        }} onClick={() => { window.open(Home_contact_us_button_click, '_blank') }}>Contact Us</Button>


                        {/* <form class="row">
                            <Col xs={6} md={4}>
                                <label for="staticEmail2" class="visually-hidden">Name</label>
                                <input type="text" class="form-control" id="staticEmail2" placeholder="Name" />
                            </Col>
                            <Col xs={6} md={4}>
                                <label for="staticEmail2" class="visually-hidden">Email</label>
                                <input type="text" class="form-control" id="staticEmail2" placeholder="Email" />
                            </Col>

                            <Col xs={6} md={4} className='mobileSubscribe'>
                                <button type="submit" class="btn btn-primary mb-3">Submit</button>
                            </Col>
                        </form> */}
                    </div>
                </div>
            </div>

        </>
    );
}

export default Carousel_section;