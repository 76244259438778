import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './screens/css/ZigZag-Border.css'
import Header from './screens/Header';
import Home from './screens/Home'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'; // Import BrowserRouter

import { About_Component } from './screens/MenuPage/AboutPage';
import { Gallery_component } from './screens/MenuPage/GalleryPage';
import { BirthdayPartyPage } from './screens/MenuPage/nextParty';
import { Feeding_Room } from './screens/MenuPage/FeedingRoom';
import { EventHub } from './screens/MenuPage/EventHub';
import { Oasis } from './screens/MenuPage/Oasis';
import { Cafe } from './screens/MenuPage/Cafe';
import { Trampoline } from './screens/MenuPage/Trampoline';
import { Arcade } from './screens/MenuPage/Arcade';
import { Montessori } from './screens/MenuPage/Montessori';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/about' element={<About_Component/>}></Route>
          <Route path='/Next-Party' element={<BirthdayPartyPage/>}></Route>
          <Route path='/gallery' element={<Gallery_component/>}></Route>
          <Route path='/FeedingRoom' element={<Feeding_Room/>}></Route>
          <Route path='/Montessori' element={<Montessori/>}></Route>
          <Route path='/EventHub' element={<EventHub/>}></Route>
          <Route path='/Oasis' element={<Oasis/>}></Route>
          <Route path='/Cafe' element={<Cafe/>}></Route>
          <Route path='/Trampoline' element={<Trampoline/>}></Route>
          <Route path='/Arcade' element={<Arcade/>}></Route>
          

          
        </Routes>
      </Router>
    </>
  );
}

export default App;
