import React, { useEffect, useState } from 'react'
import Header from '../Header'
import { Button, Col } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import '../css/Gallery.css'
import Footer from '../Footer';
import Mobile_Footer from '../MobileFooter';
import { Home_contact_us_button_click } from '../../Links';
import { referNow } from '../whatsapp';

export const Gallery_component = () => {
  const [galleryType, setgalleryType] = useState('')
  const [BlurImage, setBlurImage] = useState('')


  useEffect(() => {
    if (galleryType != '') {
      console.log("click")
      setBlurImage('disableImage')
    }
  }, [galleryType])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <Header />
      <div className='why_zenko gallery-container d-flex'>
        <div className='gallery_left'>
          <img src={require('../../assets/zenko_left.png')} />
        </div>

        <div className='About-text Gallery_Head w-100'>
          <h3 className='fw-bold mb-0'>Gallery</h3>
          <p>Photos have always been art, but with the advent and popularity of video, the beauty of both photos and the words they represent becomes even more impactful. The famous saying, "A picture says a thousand words" is germane here.
          </p>
          <div className='Gallery-Head'>

            <div className='GalleryImgContainer' style={{'justify-content': 'center'}}>
              {/* <div className={galleryType === 'Exhibits' ? 'card-container' : 'card-container ' + BlurImage} onClick={() => setgalleryType('Exhibits')}>
                <Card>
                  <div className='img_top w-100'>
                    <Card.Img variant="top" src={require('../../assets/Gallery/Exhibits/Main_img1.jpg')} />
                  </div>
                  <Card.Body>
                    <Card.Title>Exhibits</Card.Title>
                  </Card.Body>
                </Card>
              </div> */}
               <div className={galleryType === 'Activity' ? 'card-container' : 'card-container ' + BlurImage} onClick={() => setgalleryType('Activity')}>
                <Card>
                  <div className='img_top w-100'>
                    <Card.Img variant="top" src={require('../../assets/Gallery/Activity/Main_img1.JPG')} />
                  </div>
                  <Card.Body>
                    <Card.Title>Activity</Card.Title>
                  </Card.Body>
                </Card>
              </div>
              <div className={galleryType === 'Birthday' ? 'card-container' : 'card-container ' + BlurImage} onClick={() => setgalleryType('Birthday')}>
                <Card>
                  <div className='img_top w-100'>
                    <Card.Img variant="top" src={require('../../assets/Gallery/Birthday/Main_img1.jpg')} />
                  </div>
                  <Card.Body>
                    <Card.Title>Birthday</Card.Title>
                  </Card.Body>
                </Card>
              </div>
              {/* <div className={galleryType === 'Workshops' ? 'card-container' : 'card-container ' + BlurImage} onClick={() => setgalleryType('Workshops')}>
                <Card>
                  <div className='img_top w-100'>
                    <Card.Img variant="top" src={require('../../assets/Gallery/Workshops/Main_img1.jpg')} />
                  </div>
                  <Card.Body>
                    <Card.Title>Workshops</Card.Title>
                  </Card.Body>
                </Card>
              </div> */}
             
            </div>
          </div>


          <div className='mt-4 child_img'>
            {
              galleryType === 'Exhibits' ?
                <div className='row'>
                  <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Exhibits/image1.jpg')} />
                      </div>
                    </Card>
                  </div>
                  <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Exhibits/image2.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Exhibits/image3.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Exhibits/image4.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Exhibits/image5.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Exhibits/image6.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Exhibits/image7.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Exhibits/image8.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Exhibits/image9.jpg')} />
                      </div>
                    </Card>
                  </div>
                </div>
                : ''
            }

            {
              galleryType === 'Birthday' ?
                <div className='row'>
                  <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Birthday/image1.jpg')} />
                      </div>
                    </Card>
                  </div>
                  <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Birthday/image2.jpg')} />
                      </div>
                    </Card>
                  </div> 
                  {/* <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Birthday/image3.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Birthday/image4.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Birthday/image5.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Birthday/image6.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Birthday/image7.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Birthday/image8.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Birthday/image9.jpg')} />
                      </div>
                    </Card>
                  </div> */}
                </div>
                : ''
            }

            {
              galleryType === 'Workshops' ?
                <div className='row'>
                  <div className='col-4' xs={6}>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Workshops/image1.jpg')} />
                      </div>
                    </Card>
                  </div>
                  <div className='col-4' xs={6}>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Workshops/image2.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4' xs={6}>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Workshops/image3.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4' xs={6}>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Workshops/image4.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Workshops/image5.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Workshops/image6.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Workshops/image7.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Workshops/image8.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Workshops/image9.jpg')} />
                      </div>
                    </Card>
                  </div>
                </div>
                : ''
            }

            {
              galleryType === 'Activity' ?
                <div className='row'>
                  <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Activity/image1.JPG')} />
                      </div>
                    </Card>
                  </div>
                  <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Activity/image2.JPG')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Activity/image3.JPG')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Activity/image4.JPG')} />
                      </div>
                    </Card>
                  </div> 
                   {/* <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Activity/image5.JPG')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Activity/image6.JPG')} />
                      </div>
                    </Card>
                  </div> 
                  <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Activity/image7.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Activity/image8.jpg')} />
                      </div>
                    </Card>
                  </div> <div className='col-4'>
                    <Card>
                      <div className='img_top w-100'>
                        <Card.Img variant="top" src={require('../../assets/Gallery/Activity/image9.jpg')} />
                      </div>
                    </Card>
                  </div> */}
                </div>
                : ''
            }
          </div>
        </div>
      </div>

      <div className='About_contact d-flex' style={{ 'background': 'linear-gradient(0deg, rgba(238, 252, 255, 0) 0%, rgb(238 251 254) 100%) 0% 0% no-repeat padding-box padding-box transparent' }}>
        <div className='contactUs pb-3'>
          <h4 className='fw-bold mb-0 mt-4'>Contact us</h4>
         <p>Feel free to get in touch with us anytime! We're here to assist you, whether you have to plan an event, a workshop, have questions, special requests, or simply want to chat. Reach out to us and our friendly team will be delighted to help. Your satisfaction is our priority</p>

         <Button className='d-none mobileContactBtn' style={{
                background: '#FFA3BE 0% 0% no-repeat padding-box'
              }} onClick={() => { referNow() }}>Contact Us</Button>

              <Button className='desktopContactBtn contctbtn' style={{
                background: '#FFA3BE 0% 0% no-repeat padding-box',
                marginTop: '2%'            
              }} onClick={() => { window.open(Home_contact_us_button_click, '_blank') }}>Contact Us</Button>


          <div className='aboutRight'>
            <img src={require('../../assets/Plane_blue_right.png')} />
          </div>
        </div>
      </div>


      <div className="girls" style={{
        width: '30%',
        margin: '5% auto 2% auto'
      }}>
        <img src={require('../../assets/glry_kid.png')} />
      </div>


      <div className='Desktop_footer'>
        <Footer page={'gallery'} BG_color={'linear-gradient(0deg, rgba(238, 252, 255, 0) 0%, rgb(238, 251, 254) 100%) 0% 0% no-repeat padding-box padding-box transparent'}></Footer>
      </div>
      <div className='Mobile_Footer d-none'>
        <Mobile_Footer page={'gallery'} BG_color={'linear-gradient(0deg, rgba(238, 252, 255, 0) 0%, rgb(238, 251, 254) 100%) 0% 0% no-repeat padding-box padding-box transparent'}></Mobile_Footer>
      </div>

    </>
  )
}
