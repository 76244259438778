import React, { useEffect } from 'react'
import Header from '../Header'
// import '../css/About.css'
import Footer from '../Footer'
import Mobile_Footer from '../MobileFooter'
import { Button, Col } from 'react-bootstrap'
import { Home_contact_us_button_click } from '../../Links'
import { referNow } from '../whatsapp'

export const Feeding_Room = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <Header />
      <div>
        <div className='Feeding-zigzag'>
          <div className='why_zenko mb-4 d-flex'>
          <a href="/" class="back-sign">&lt;Back</a>

            <div className='AboutPage-text' style={{ margin: '2% auto' }}>
              <h4 className='fw-bold mb-2'>Baby feeding corner</h4>
              <p className='menu-type-text'>Welcome to our Baby Feeding corner, a warm and comfortable haven designed with the utmost care for both babies and caregivers. Our space is thoughtfully equipped to provide a peaceful and convenient environment for feeding and nurturing your little ones. Whether you're breastfeeding, bottle-feeding, or simply seeking a tranquil moment with your baby, our room is here to ensure you and your child feel relaxed and supported throughout this special bonding time.</p>
            </div>
          </div>

          <div style={{ position: 'relative', paddingBottom: '4%' }}>

            <div className='FeedingImg' style={{width: '20%',margin: '0% auto'}}>
              <div className='img_top w-100'>
                <img src={require('../../assets/FeedingRoom/feedingWhiteInner.png')} />
              </div>
            </div>


          </div>
        </div>

        <div className='Feeding-contact-zigzag'>

          <div className='About_contact d-flex pt-1'>

            <div className='contactUs pb-3'>
              <h4 className='fw-bold mb-0 mt-4'>Contact us</h4>
              <p>Feel free to get in touch with us anytime! We're here to assist you, whether you have to plan an event, a workshop, have questions, special requests, or simply want to chat. Reach out to us and our friendly team will be delighted to help. Your satisfaction is our priority</p>

              <Button className='d-none mobileContactBtn' style={{
                background: '#FFA3BE 0% 0% no-repeat padding-box'
              }} onClick={() => { referNow() }}>Contact Us</Button>

              <Button className='desktopContactBtn contctbtn' style={{
                background: '#FFA3BE 0% 0% no-repeat padding-box'
              }} onClick={() => { window.open(Home_contact_us_button_click, '_blank') }}>Contact Us</Button>


            </div>
            <div className='About_sideImg'>
              <img src={require('../../assets/zenko_left.png')} />
            </div>
          </div>

          <div className="girls" style={{
            width: '30%',
            margin: '0 auto',
            padding: '5% 0'
          }}>
            <img src={require('../../assets/kids_logo.png')} />
          </div>
        </div>

        <div className='Desktop_footer'>
          <Footer></Footer>
        </div>
        <div className='Mobile_Footer d-none'>
          <Mobile_Footer></Mobile_Footer>
        </div>

      </div>

    </>
  )
}
