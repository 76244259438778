import React from 'react';
import { Button } from 'react-bootstrap';
import './css/Main.css'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import AboutUs from './About';
import { useNavigate } from 'react-router-dom';


const Gallery_Section = () => {
    const navigate = useNavigate()

    return (
        <>

            <div className='Gallery_section mt-2'>
                <div className='Gallery_section_leftImg'>
                    <img src={require('../assets/zenko_left.png')} style={{ 'marginTop': '-40%' }} />
                </div>

                <div className='Gallery_section_Inner'>
                    <h4 className='fw-bold mb-4' style={{'font-size': '25px'}}>Gallery</h4>
                    <div className='Gallery-Row-Container'>
                        <Row>
                            <Col xs={6} md={4} className='p-0 gallery-image'>
                                <Card className='Card_Gallery' style={{ 'transform': 'rotate(8deg)' }}>
                                    <Card.Img variant="top" src={require('../assets/HomeGallery/image1.jpg')} />
                                    {/* <Card.Body>
                                        <Card.Text>
                                            Lorem Ipsum is simply
                                        </Card.Text>
                                    </Card.Body> */}
                                </Card>
                            </Col>
                            <Col xs={6} md={4} className='p-0 gallery-image'>
                                <Card className='Card_Gallery' style={{ 'transform': 'rotate(-2deg)' }}>
                                    <Card.Img variant="top" src={require('../assets/HomeGallery/image2.jpg')} />
                                </Card>
                            </Col>
                            <Col xs={6} md={4} className='p-0 gallery-image'>
                                <Card className='Card_Gallery' style={{ 'transform': 'rotate(-5deg)' }}>
                                    <Card.Img variant="top" src={require('../assets/HomeGallery/image3.jpg')} />
                                </Card>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={6} md={4} className='p-0 gallery-image'>
                                <Card className='Card_Gallery' style={{ 'transform': 'rotate(-2deg)' }}>
                                    <Card.Img variant="top" src={require('../assets/HomeGallery/image4.jpg')} />
                                </Card>
                            </Col>
                            <Col xs={6} md={4} className='p-0 gallery-image'>
                                <Card className='Card_Gallery' style={{ 'transform': 'rotate(4deg)' }}>
                                    <Card.Img variant="top" src={require('../assets/HomeGallery/image5.jpg')} />
                                </Card>
                            </Col>
                            <Col xs={6} md={4} className='p-0 gallery-image'>
                                <Card className='Card_Gallery' style={{ 'transform': 'rotate(2deg)' }}>
                                    <Card.Img variant="top" src={require('../assets/HomeGallery/image6.jpg')} />
                                </Card>
                            </Col>
                        </Row>
                        <div className='gallery_red_arrow'>
                            <img src={require('../assets/pink_arrow.png')} />
                        </div>
                        <Button className='ExploreMoreBtn' style={{ 'marginTop': '-42px' }} onClick={()=>navigate('/gallery')}>Explore More</Button>
                    </div>
                </div>

            </div>


            <div className='banner-zigzag'>
                <div className='banner'>
                    <div>
                        <img src={require('../assets/kids_store.png')} />
                    </div>
                    <div>
                        <img src={require('../assets/sale.png')} />
                    </div>
                    <div>
                        <img src={require('../assets/kids_place.png')} />
                    </div>
                </div>
            </div>


            <div className='only_img'>
                <img src={require('../assets/kids_logo.png')} />
            </div>
        </>
    );
}

export default Gallery_Section;